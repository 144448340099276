import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { userRoutes } from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";
import HorizontalLayout from "./components/HorizontalLayout/";

// Google analytics
import ReactGA from "react-ga4";

// Import scss
import "./assets/scss/theme.scss";

initializeReactGA();

const App = () => {
  let dispatch = useDispatch();
  const Layout = HorizontalLayout;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "ETH_DISPATCH_CLOCK" });
    }, 500);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx} exact />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

function initializeReactGA() {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.send("pageview");
  }
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return { layout: state.Layout };
};

export default connect(mapStateToProps, null)(App);
