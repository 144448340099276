import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../../Common/Blurred/BlurredNumber";
import { Alert, Col, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
import { priceCall } from "../../../helpers/secure_position_helper";
import { BNToDecimal } from "../../../helpers/number_format_helper";
import {
  selectAsset,
  selectAssetPrice,
  selectDuration,
  selectPayout,
  selectTriggerPrice,
} from "../../../store/securePositionModal/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { policy } from "../../../config";

const PolicyPrice = ({ t, asset, assetPrice, triggerPrice, payoutAmount, duration, premium }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "policyPrice",
      componentEthCalls: priceCall(asset, assetPrice, triggerPrice, payoutAmount, duration),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "policyPrice" });
      mounted.current = false;
    };
  }, [dispatch, asset, assetPrice, triggerPrice, payoutAmount, duration]);

  return (
    <>
      <Row>
        <Col id="policy-price">
          $<BlurredNumber number={premium} options={{ maximumFractionDigits: 2, notation: "compact" }} />
        </Col>
      </Row>
      {premium && premium.value < policy.minimumPrice && (
        <Alert variant="warning">
          {t("Sorry, the minimum price of a policy is " + policy.minimumPrice + "USD, try changing the payout")}
        </Alert>
      )}
    </>
  );
};

PolicyPrice.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const asset = selectAsset(state.SecurePositionModalReducer);
  const assetPrice = selectAssetPrice(state.SecurePositionModalReducer);
  const triggerPrice = selectTriggerPrice(state.SecurePositionModalReducer);
  const payoutAmount = selectPayout(state.SecurePositionModalReducer);
  const duration = selectDuration(state.SecurePositionModalReducer);

  const [policyPrice] = selectEthCallMultiple(
    state.EthereumReducer,
    priceCall(asset, assetPrice, triggerPrice, payoutAmount, duration)
  ) || [{}];

  const premium = policyPrice?.value ? { value: BNToDecimal(policyPrice.value?.premium, 6), state: "LOADED" } : {};
  return { asset, assetPrice, triggerPrice, payoutAmount, duration, premium };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(PolicyPrice));
