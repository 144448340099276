import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import ActivePoliciesList from "./ActivePoliciesList";
import { Row } from "reactstrap";
/* Helpers and Selectors */
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
import { selectUserAddress } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { environment } from "../../config";
import { automations } from "../../chainConfigs.js";
import { compose } from "redux";
import "./style.scss";

const componentEthCalls = (userAddress) => {
  return automations[environment.network].map((aut) => {
    return { address: aut.address, abi: "PayoutAutomation", method: "balanceOf", args: [userAddress] };
  });
};

const ActivePolicies = ({ t, userAddress, automationBalances }) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "activePolicies",
      componentEthCalls: componentEthCalls(userAddress),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "activePolicies" });
      mounted.current = false;
    };
  }, [dispatch, userAddress]);

  return (
    <>
      {automationBalances.some((ab) => ab?.value > 0) && (
        <>
          <h3>{t("Active policies")}</h3>
          <br />
          <Row>
            {automations[environment.network].map((aut, autIdx) => {
              return (
                automationBalances[autIdx]?.value > 0 && (
                  <ActivePoliciesList
                    userAddress={userAddress}
                    balance={automationBalances[autIdx].value}
                    contract={aut.address}
                    automation={t(aut.name)}
                    key={autIdx}
                  />
                )
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

ActivePolicies.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userAddress = selectUserAddress(state.UserReducer);
  const automationBalances = selectEthCallMultiple(state.EthereumReducer, componentEthCalls(userAddress));

  return { userAddress, automationBalances };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(ActivePolicies));
