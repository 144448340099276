// User Redux States
import { SET_USER, SET_PROVIDER, SET_CHAIN_ID } from "./actionTypes";

const INIT_STATE = {
  address: null,
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  error: {
    message: "",
  },
  chainId: null,
  providerId: null,
  canTransact: false,
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      state = { ...state, address: action.payload, canTransact: action.canTransact };
      break;

    case SET_CHAIN_ID:
      state = { ...state, chainId: action.payload };
      break;

    case SET_PROVIDER:
      state = { ...state, providerId: action.payload };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserReducer;
