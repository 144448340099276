import Big from "big.js";
import { strToBlockchainData, strToDec, strToDecZ, toDecimal } from "./number_format_helper";
import { currency, healthFactor, environment } from "../config";
import { getContract } from "../contractRegistry";
import { usdcDomain, types } from "./eth_store_helper";
import { assets, automations } from "../chainConfigs.js";

const { ethers } = require("ethers");

export const getColorFromHf = (hf) => {
  if (hf === undefined || hf === "") {
    return "2px solid #ffffff"; // white
  } else if (hf > 2) {
    return "2px solid #00ff00"; // green
  } else if (hf >= healthFactor.middleHf) {
    return "2px solid #ffff00"; // yellow
  } else {
    return "2px solid #ff0000"; // red
  }
};

export const getSuggestedTriggerPrice = (hf, price) => {
  if (hf?.value && price?.value) {
    let liq = (healthFactor.middleHf * price.value) / hf.value;
    return liq.toFixed(3).toString();
  } else {
    return "";
  }
};

export const getHealthFactor = (healthFactor, price, amount) => {
  if (healthFactor?.value && price?.value && amount) {
    let hf = (amount * healthFactor.value) / price.value;
    return hf.toFixed(2);
  } else {
    return "";
  }
};

export const getPayoutByAutomation = (automation, amount) => {
  if (automation === "buyTheDip") {
    return getAcquiredCollateral(amount).toFixed(2).toString();
  } else if (automation === "repayLoan") {
    return getRepayAmount(amount).toFixed(2).toString();
  } else {
    return "";
  }
};

export const getAmountByAsset = (automation, price, aaveUserData, triggerPrice) => {
  if (automation === "buyTheDip" && price && aaveUserData && triggerPrice) {
    return (aaveUserData?.totalCollateralBase?.value / price?.value) * toDecimal(triggerPrice);
  } else if (automation === "repayLoan" && price && aaveUserData && triggerPrice) {
    return aaveUserData?.totalDebtBase?.value;
  } else {
    return "";
  }
};

// amount could be collateral or debt
export const getHfByAutomation = (automation, middleHf, amount, payout) => {
  if (automation === "buyTheDip" && middleHf && amount && strToDecZ(payout)) {
    return strToDec(amount).plus(payout).mul(middleHf).div(amount).toFixed(2);
  } else if (automation === "repayLoan" && middleHf && amount && strToDecZ(payout)) {
    return amount.gt(payout) ? ((middleHf * amount) / (amount - payout)).toFixed(2) : "";
  } else {
    return "";
  }
};

export const getRepayAmount = (totalBorrows, initialHf = healthFactor.middleHf) => {
  return totalBorrows - (initialHf / (initialHf * healthFactor.finalHf)) * totalBorrows;
};

/**
 * To calculate payout, the collateral is the collateral at trigger price
 */
export const getAcquiredCollateral = (collateral, initialHf = healthFactor.middleHf) => {
  return (collateral * initialHf * healthFactor.finalHf) / initialHf - collateral;
};

export const getTimestampFromDays = (days) => {
  return Math.floor(new Date().getTime() / 1000) + days * 24 * 60 * 60;
};

/**
 * Dispatch approve sign transaction to the blockchain
 */
export const dispatchEIPSign = async (dispatch, automation, userAddress, value) => {
  let deadline = Math.floor(Date.now() / 1000) + 60 * 60; // 1 hour
  const usdcContract = getContract(currency.address, "ERC20Permit");
  const spender = getAutomationAddress(automation);
  dispatch({
    type: "ETH_EIP_712_SIGN",
    domain: usdcDomain,
    types: types,
    value: {
      owner: ethers.utils.getAddress(userAddress),
      spender: ethers.utils.getAddress(spender),
      value: value,
      nonce: await usdcContract.nonces(userAddress),
      deadline: deadline,
    },
  });
};

/**
 * Dispatch a transaction to the blockchain depending on the automation
 */
export const dispatchSecurePositionByAutomation = (
  dispatch,
  automation,
  asset,
  triggerPrice,
  lower,
  payout,
  duration,
  onBehalfOf,
  permitSign
) => {
  const automationAddress = getAutomationAddress(automation);
  const sig = ethers.utils.splitSignature(permitSign.signature);
  dispatch({
    type: "ETH_TRANSACT",
    method: "newPolicyWithPermit",
    abi: "PayoutAutomation",
    address: automationAddress,
    args: [
      asset.priceRiskModule,
      triggerPrice,
      lower,
      payout,
      duration,
      onBehalfOf,
      permitSign.value.value,
      permitSign.value.deadline,
      sig.v,
      sig.r,
      sig.s,
    ],
  });
};

export const getAutomationAddress = (automation) => {
  return automations[environment.network].find((aut) => aut.key === automation)?.address;
};

export const getAssetByPriceRM = (priceRM) => {
  const asset = assets[environment.network].filter((asset) => asset.priceRiskModule === priceRM);
  return asset[0];
};

export const priceCall = (asset, assetPrice, triggerPrice, payoutAmount, duration) => {
  let tp = strToBlockchainData(triggerPrice, 18);
  let p = strToBlockchainData(payoutAmount, 6);
  if (tp && p && assetPrice && assetPrice.value) {
    return [
      {
        address: asset.priceRiskModule,
        abi: "PriceRiskModule",
        method: "pricePolicy",
        args: [tp, assetPrice.value.gt(Big(triggerPrice)), p, duration.timestamp],
      },
    ];
  }
  return [];
};
