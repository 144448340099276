import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../../Common/Blurred/BlurredNumber";
import { Alert, Card, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { getColorFromHf } from "../../../helpers/secure_position_helper";
import {
  selectMiddleHealthFactor,
  selectFinalHealthFactor,
  selectAutomation,
} from "../../../store/securePositionModal/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import "../style.scss";

const HealthFactorPoints = ({ t, hf, targetHf, finalHf, automation }) => {
  return (
    <>
      <Row id="centered">
        <Card id="hf-card" style={{ border: getColorFromHf(hf.value) }}>
          <BlurredNumber number={hf} options={{ maximumFractionDigits: 2, notation: "compact" }} />
        </Card>
        <Card id="arrow-card">
          <i className="uil uil-arrow-right" />
        </Card>
        <Card id="hf-card" style={{ border: getColorFromHf(targetHf) }}>
          {targetHf}
        </Card>
        {automation !== "receiveMoney" && (
          <>
            <Card id="arrow-card">
              <i className="uil uil-arrow-right" />
            </Card>
            <Card id="hf-card" style={{ border: getColorFromHf(finalHf) }}>
              {finalHf}
            </Card>
          </>
        )}
      </Row>
      {targetHf && targetHf < 1 && (
        <Alert variant="warning">{t("That trigger price will not prevent the liquidation of Aave!")}</Alert>
      )}
    </>
  );
};

HealthFactorPoints.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const targetHf = selectMiddleHealthFactor(state.SecurePositionModalReducer);
  const finalHf = selectFinalHealthFactor(state.SecurePositionModalReducer);
  const automation = selectAutomation(state.SecurePositionModalReducer);
  return { targetHf, finalHf, automation };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(HealthFactorPoints));
