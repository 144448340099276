import { selectProvider } from "../store/user/selectors";
import { chain, currency } from "../config";

const { ethers } = require("ethers");

export function validTransaction(lastTransaction) {
  return !lastTransaction || !(lastTransaction && !lastTransaction.state && lastTransaction.state !== "QUEUED");
}

export function customErrorParser(error) {
  const stringToFind = 'reason":"';
  let custom = JSON.stringify(error);
  if (custom && custom.includes(stringToFind)) {
    let start_index = custom.indexOf(stringToFind) + stringToFind.length;
    let end_index = custom.indexOf('",', start_index);
    let new_string = custom.slice(start_index, end_index);
    return new_string;
  }
  return undefined;
}

// EIP 712
export const usdcDomain = {
  name: currency.name,
  version: currency.version,
  chainId: chain.id,
  verifyingContract: currency.address,
};

export const types = {
  Permit: [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ],
};

export async function signMessageTyped(userState, domain, types, value) {
  const provider = new ethers.providers.Web3Provider(selectProvider(userState), "any");
  const signer = provider.getSigner();
  const signatureHash = await signer._signTypedData(domain, types, value);
  return signatureHash;
}
