import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
/* Helpers and Selectors */
import { selectAutomation } from "../../../store/securePositionModal/selectors";
import { automations } from "../../../chainConfigs";
import { environment } from "../../../config";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import "../style.scss";

const Automation = ({ t, automation }) => {
  let dispatch = useDispatch();

  const handleAutomation = (automation) => {
    dispatch({ type: "SET_AUTOMATION", automation: automation });
  };

  return (
    <Row>
      {automations[environment.network].map((aut, idx) => (
        <Col md={4} xs={4} key={idx}>
          <Button
            id="automation-btn"
            variant={aut.key === automation ? "secondary" : "sky-blue"}
            onClick={() => handleAutomation(aut.key)}
          >
            {aut.name}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

Automation.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const automation = selectAutomation(state.SecurePositionModalReducer);
  return { automation };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(Automation));
