import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
/* i18n */
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { environment } from "../../config";
import logo from "../../assets/images/logo-dark.png";

const Footer = ({ t }) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6} xs={6}>
              {new Date().getFullYear()} © NoLiq - {environment.version}.
            </Col>
            <Col md={6} xs={6} style={{ textAlign: "end" }}>
              <Link to="//ensuro.co/" target="_blank" style={{ color: "#74788D" }}>
                <>
                  {t("Powered by ")}
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </>
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

Footer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Footer));
