import React from "react";
import PropTypes from "prop-types";
import useGeoLocation from "react-ipgeolocation";
/* Components */
import { Card } from "semantic-ui-react";
import { Container } from "reactstrap";
import ActivePolicies from "../../components/ActivePolicies/ActivePolicies";
import ConnectButton from "../../components/Common/ConnectButton";
import Positions from "../../components/Positions/Positions";
/* i18n */
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { blockedCountries } from "../../config";
import shield from "../../assets/images/shield.png";
import "./style.scss";

const Dashboard = ({ t, user }) => {
  const location = useGeoLocation();

  return (
    <React.Fragment>
      <div className="page-content">
        {user.address ? (
          <Container fluid>
            <ActivePolicies user={user} />
            <Positions user={user} />
          </Container>
        ) : (
          <div className="centered">
            <Card className="connect-wallet-card align-items-center">
              {!blockedCountries.includes(location.country) ? (
                <>
                  <img src={shield} alt="Shield" height={184} width={184} />
                  <h2>{t("Please, connect your wallet")}</h2>
                  <h4>{t("Please connect your wallet to see your ensured positions and ensure your assets.")}</h4>
                  <br />
                  <br />
                  <ConnectButton user={user} />
                </>
              ) : (
                <>
                  <img src={shield} alt="Shield" height={184} width={184} />
                  <h2>{t("Restricted Access")}</h2>
                  <h4>{t("We're sorry, but currently, it's not possible to use this application in your country.")}</h4>
                </>
              )}
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  return { user };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(Dashboard));
