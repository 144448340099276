import { createSelector } from "reselect";
import { getAmountByAsset, getHealthFactor, getHfByAutomation } from "../../helpers/secure_position_helper";

const getAaveUserData = (state) => state.aaveUserData;
const getAssetPrices = (state) => state.assetPrices;
const getAsset = (state) => state.asset;
const getTriggerPrice = (state) => state.triggerPrice;
const getPayout = (state) => state.payoutAmount;
const getDuration = (state) => state.duration;
const getAutomation = (state) => state.automation;

const getUserHealthFactor = (state) => state.aaveUserData?.healthFactor;
const getUserTotalDebtBase = (state) => state.aaveUserData?.totalDebtBase;

export const selectAaveUserData = createSelector([getAaveUserData], (aaveUserData) => aaveUserData);
export const selectTotalDebtBase = createSelector([getUserTotalDebtBase], (totalDebtBase) => totalDebtBase);
export const selectAssetPrices = createSelector([getAssetPrices], (assetPrices) => assetPrices);
export const selectAsset = createSelector([getAsset], (asset) => asset);
export const selectTriggerPrice = createSelector([getTriggerPrice], (triggerPrice) => triggerPrice);
export const selectPayout = createSelector([getPayout], (payout) => payout);
export const selectDuration = createSelector([getDuration], (duration) => duration);
export const selectAutomation = createSelector([getAutomation], (automation) => automation);

export const selectAssetPrice = createSelector([getAsset, getAssetPrices], (asset, assetPrices) =>
  asset ? assetPrices[asset.name] : undefined
);

// export const selectUserHealthFactor = createSelector([getUserHealthFactor], (hf) => hf);

export const selectMiddleHealthFactor = createSelector(
  [getTriggerPrice, getAsset, getAssetPrices, getUserHealthFactor],
  (triggerPrice, asset, assetPrices, hf) => {
    return getHealthFactor(hf, assetPrices[asset.name], triggerPrice);
  }
);

export const selectFinalHealthFactor = createSelector(
  [getUserHealthFactor, getAutomation, getAssetPrices, getAsset, getTriggerPrice, getPayout, getAaveUserData],
  (hf, automation, assetPrices, asset, triggerPrice, payout, aaveUserData) => {
    const middleHf = getHealthFactor(hf, assetPrices[asset.name], triggerPrice);
    const amount = getAmountByAsset(automation, assetPrices[asset.name], aaveUserData, triggerPrice);
    return getHfByAutomation(automation, middleHf, amount, payout);
  }
);
