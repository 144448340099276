import _ from "lodash";
import { BNToDecimal } from "./helpers/number_format_helper.js";
import { registerABI, registerFormatter } from "./contractRegistry.js";
import { currency } from "./config.js";

registerABI("ERC20Permit", require("@openzeppelin/contracts/build/contracts/ERC20Permit.json").abi);
registerABI("AavePool", require("./abis/AavePool.json").abi);
registerABI("PriceOracle", require("./abis/PriceOracle.json").abi);
registerABI(
  "PriceRiskModule",
  require("@ensuro/price-risk-module/build/contracts/PriceRiskModule.sol/PriceRiskModule.json").abi
);
registerABI(
  "PayoutAutomation",
  require("@ensuro/price-risk-module/build/contracts/interfaces/IPayoutAutomation.sol/IPayoutAutomation.json").abi
);

// AavePool methods
registerFormatter("AavePool", "getUserAccountData", undefined);

// PriceOracle methods
registerFormatter("PriceOracle", "getCurrentPrice", _.partial(BNToDecimal, _, 18));

// PriceRiskModule methods
registerFormatter("PriceRiskModule", "pricePolicy", undefined);
registerFormatter("PriceRiskModule", "getPolicyData", undefined);

// ERC20 methods
registerFormatter("ERC20Permit", "allowance", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("ERC20Permit", "nonces", undefined);

// PayoutAutomation methods
registerFormatter("PayoutAutomation", "balanceOf", _.partial(BNToDecimal, _, 0));
