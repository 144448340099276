import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";
// Import walletBtn
import ConnectButton from "../Common/ConnectButton";
import SwitchNetworkButton from "../Common/SwitchNetworkButton";
import LanguageDropdown from "../Common/TopbarDropdown/LanguageDropdown";

import logo from "../../assets/images/NoLiq-sm.png";
import logoDark from "../../assets/images/NoLiq-Logo.png";

//i18n
import { withTranslation } from "react-i18next";

const Header = (props) => {
  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="80" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            style={{ color: "black" }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>

        <div className="d-flex">
          <SwitchNetworkButton user={props.user} />
          <ConnectButton user={props.user} />
          <LanguageDropdown />
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  t: PropTypes.any,
  leftMenu: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, leftMenu } = state.Layout;
  const user = state.UserReducer;
  return { layoutType, leftMenu, user };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Header));
