import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./style.scss";
import { connect } from "react-redux";
import { compose } from "redux";
import { chain } from "../../config";
import { selectProvider } from "../../store/user/selectors";

const WrongNetworkAlert = ({ t, userState }) => {
  const switchNetwork = async () => {
    let provider = selectProvider(userState);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.hexId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain.hexId,
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                blockExplorerUrls: [chain.scan],
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <Alert variant="danger">
      {t("Please switch to ")} {chain.name}.
      <strong id="btn-text" onClick={switchNetwork}>
        {t("[Switch network]")}
      </strong>
    </Alert>
  );
};

WrongNetworkAlert.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userState = state.UserReducer;
  return { userState };
};

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(WrongNetworkAlert));
