import { ethers } from "ethers";
import { chain, track } from "../../../config";
import { roProvider } from "../../../providers";
import { createModal } from "./createModal";

function trackWallet() {
  const walletName = track.name;

  return () => {
    return {
      label: walletName,
      getIcon: async () => (await import("../../../assets/images/glass.png")).default,
      getInterface: async ({ EventEmitter, chains }) => {
        const { createEIP1193Provider, ProviderRpcErrorCode, ProviderRpcError } = await import("./Provider/src");
        const emitter = new EventEmitter();
        if (!chains.length) throw new Error("At least one Chain must be passed to onboard in order to connect");

        let trackWalletProvider = roProvider;
        let provider;
        let address;

        function showCustomPrompt() {
          createModal();
          const overlay = document.getElementById("overlay");
          const error = document.getElementById("error-message");
          const customPrompt = document.getElementById("custom-prompt");
          const cancelButton = document.getElementById("cancel-button");
          const confirmButton = document.getElementById("confirm-button");
          const addressInput = document.getElementById("address-input");

          return new Promise((resolve, reject) => {
            cancelButton.addEventListener("click", () => {
              overlay.remove();
              customPrompt.remove();
              reject("Prompt canceled by user");
            });

            confirmButton.addEventListener("click", () => {
              const address = addressInput.value.trim();
              if (ethers.utils.isAddress(address)) {
                overlay.remove();
                customPrompt.remove();
                localStorage.setItem("trackWalletAddress", address);
                resolve([address]);
              } else {
                error.innerText = "Invalid address";
                error.style.display = "block";
              }
            });
          });
        }

        function patchProvider() {
          const patchedProvider = createEIP1193Provider(trackWalletProvider, {
            eth_requestAccounts: async ({ baseRequest }) => {
              try {
                address = localStorage.getItem("trackWalletAddress");
                if (!address || address === "null") {
                  return await showCustomPrompt();
                } else {
                  return new Promise((resolve, reject) => {
                    resolve([address]);
                  });
                }
              } catch (error) {
                throw new ProviderRpcError({
                  code: ProviderRpcErrorCode.ACCOUNT_ACCESS_REJECTED,
                  message: "Account access rejected",
                });
              }
            },
            eth_chainId: async () => {
              try {
                return new Promise((resolve, reject) => {
                  resolve(chain.hexId);
                });
              } catch (error) {
                throw new ProviderRpcError({
                  code: ProviderRpcErrorCode.ACCOUNT_ACCESS_REJECTED,
                  message: "Account access rejected",
                });
              }
            },
            eth_accounts: async () => {
              try {
                return new Promise((resolve, reject) => {
                  if (address) resolve([address]);
                  else reject("No address");
                });
              } catch (error) {
                throw new ProviderRpcError({
                  code: ProviderRpcErrorCode.ACCOUNT_ACCESS_REJECTED,
                  message: "Account access rejected",
                });
              }
            },
            eth_sendTransaction: async () => {
              throw new ProviderRpcError({
                code: ProviderRpcErrorCode.UNSUPPORTED_METHOD,
                message: "The Provider does not support the requested method: eth_sendTransaction",
              });
            },
            eth_sign: async () => {
              throw new ProviderRpcError({
                code: ProviderRpcErrorCode.UNSUPPORTED_METHOD,
                message: "The Provider does not support the requested method: eth_sign",
              });
            },
          });

          if (!provider) {
            patchedProvider.on = emitter.on.bind(emitter);
            return patchedProvider;
          } else {
            provider.request = patchedProvider.request.bind(patchedProvider);
            provider.send = patchedProvider.send.bind(patchedProvider);
            provider.sendAsync = patchedProvider.sendAsync.bind(patchedProvider);
            return provider;
          }
        }

        provider = patchProvider();

        return {
          provider,
        };
      },
    };
  };
}

export default trackWallet;
