import { call, put, takeEvery, take /*, all*/ } from "redux-saga/effects";
import { getProvider } from "../../providers";
import { eventChannel, END } from "redux-saga";

// User Redux States
import { SET_PROVIDER, SET_USER, SET_CHAIN_ID } from "./actionTypes";

function providerListenerChannel(provider) {
  return eventChannel((emitter) => {
    /*if (provider.removeAllListeners)
      provider.removeAllListeners();*/
    provider.on("accountsChanged", (evtData) => {
      emitter({
        type: SET_USER,
        payload: evtData[0],
        canTransact: true, // Track Wallet can't emit this event, so it's always true
      });
    });

    provider.on("chainChanged", (evtData) => {
      emitter({
        type: SET_CHAIN_ID,
        payload: parseInt(evtData, 16),
      });
    });

    provider.on("disconnect", () => {
      emitter({
        type: SET_PROVIDER,
        payload: null,
      });
      emitter(END);
    });
    return () => {};
  });
}

export function* listenToEvents({ payload }) {
  if (payload == null) return;
  const channel = yield call(providerListenerChannel, getProvider(payload));
  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } finally {
  }
}

export function* userSaga() {
  yield takeEvery(SET_PROVIDER, listenToEvents);
}

export default userSaga;
