import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import UserReducer from "./user/reducer";
import EthereumReducer from "ethereum-store/src/store/ethereum/reducer";
import SecurePositionModalReducer from "./securePositionModal/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  UserReducer,
  EthereumReducer,
  SecurePositionModalReducer,
});

export default rootReducer;
