import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rollbarMiddleware from "rollbar-redux-middleware";

import rootReducer from "./reducers";
import rootSaga from "./sagas";
import "../initialize";
import rollbar from "../rollbar";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rollbarRedux = rollbarMiddleware(rollbar, [], true);

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, rollbarRedux)));
sagaMiddleware.run(rootSaga);

export default store;
