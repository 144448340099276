import React from "react";
import App from "./App";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Buffer } from "buffer";
import * as serviceWorker from "./serviceWorker";

import rollbar from "./rollbar";
import store from "./store";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <RollbarProvider instance={rollbar}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </RollbarProvider>
    </BrowserRouter>
  </Provider>
);

window.Buffer = window.Buffer || Buffer;
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(app);

serviceWorker.unregister();
