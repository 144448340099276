import React from "react";
import aaveLogo from "../../assets/images/aave-logo.png";
import ethLogo from "../../assets/images/eth-logo.png";
import btcLogo from "../../assets/images/wbtc-logo.png";
import maticLogo from "../../assets/images/matic-logo.png";
import "./style.scss";

const assetLogo = {
  Aave: aaveLogo,
  "ETH Price": ethLogo,
  "BTC Price": btcLogo,
  "WMATIC Price": maticLogo,
};

const Asset = ({ image, name, font = 20, height = 35 }) => {
  return (
    <div className={`font-size-${font}`}>
      <p id="p-without-margin" style={{ color: "black", fontWeight: "600" }}>
        <span className="logo-sm">
          <img src={image || assetLogo[name]} alt={name} height={height} />
        </span>{" "}
        {name}
      </p>
    </div>
  );
};

export default Asset;
